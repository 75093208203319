import React from 'react';
import '../App.css';
import aboutus from '../images/aboutus.jpg';
import mindwave from '../images/mindwave.png';
import skdp from '../images/skdp.jpg';
import amdp from '../images/amdp.jpg';

function Aboutus() {
  const containerStyle = {
    position: 'relative',
    top: '0px',
    width: '100%',
    height: '515px',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${aboutus})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    // border: '1px white solid',
  };

  const overlayStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${mindwave}), radial-gradient(rgba(0,0,0,0.5) 10%, rgba(0,0,0,0.9) 60%)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundBlendMode: 'overlay',
    filter: 'brightness(4)',
    // border: '1px white solid',
  };

  const container = {
    position: 'absolute',
    margin: '100px 50px 0 50px',
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
      // border: '1px white solid',
  };

  const textStyle1 = {
    position: 'relative',
    fontWeight: 'bold',
    fontSize: '60px',
    textAlign: 'center',
    color: 'white',
    // border: '1px white solid',
  };

  const textStyle2 = {
    position: 'relative',
    textAlign: 'center',
    fontWeight: 400,  
    fontSize: '20px',
    color: 'white',
    // border: '1px white solid',
  };

  const content = {
    position: 'relative',
    color: 'black',
    backgroundColor: 'white',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    zIndex: -1,
    display: 'flex',
    justifyContent: 'center',
    // border: '1px red solid',
  }; 


  const cardStyle = {
    display: 'flex',
    height: 'auto',
  };

  const cardTextStyle1 = {
    // border: '1px red solid',
    flex: 6,
    padding: '0 0 0 30px',
  };

  const cardTextStyle2 = {
    // border: '1px red solid',
    flex: 6,
    padding: '0 30px 0 0px',
  };

  const head = {
    display: 'flex',
    margin: '50px 10px 10px 10px',
    fontWeight: 1000,
    fontSize: '40px',
    color: 'orange',
  }

  const para = {
    display: 'flex',
    margin: '10px',
    textAlign: 'left',
    fontSize: '20px',
  }

  const name = {
    fontWeight: 'bold',
    margin: '10px 0 0 0',
  }

  const cardImageStyle = {
    flex: 2,
    // border: '1px red solid',
    borderRadius: '20px',
    padding: '200px 0 0 0',
  };

  return (
    <div>
      <div style={containerStyle}>
        <div style={overlayStyle}></div>
        <div style={container}> 
          <div style={textStyle1}>About Us</div>
          <div style={textStyle2}>Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum? amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum?</div>
        </div>
      </div>
      <div style={content}>
        <div style={cardStyle}>
          <div style={cardTextStyle1}>
            <h1 style={head}>About Me</h1>
            <p style={para}>Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum? amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum?</p>
          </div>
          <div style={cardImageStyle}>
            <img src={skdp} width="200px"/>
            <div style={name}>Shyam Kapur</div>
          </div>
        </div>
      </div>
      <div style={content}>
        <div style={cardStyle}>
          <div style={cardImageStyle}>
            <img src={amdp} width="200px"/>
            <div style={name}>Anesh Madapoosi</div>
          </div>
          <div style={cardTextStyle2}>
            <h1 style={head}>Anesh Madapoosi</h1>
            <p style={para}>Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum? amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum?</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;