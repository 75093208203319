import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import background from '../images/studybuddy.png';
import mindwave from '../images/mindwave.png';
import '../App.css';

function StudyBuddy() {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollToContent) {
      document.getElementById('learnmore').scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '515px',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
  };
  const overlayStyle = {
    position: 'absolute',
    width: '100%',
    height: '120%',
    backgroundImage: `url(${mindwave}), radial-gradient(rgba(0,0,0,0.5) 10%, rgba(0,0,0,0.9) 60%)`,
    backgroundSize: 'cover',
    backgroundBlendMode: 'overlay',
    filter: 'brightness(6)',
  };
  const container = {
    position: 'absolute',
    top: '40%',
    display: 'flex',
    left: '100px',
    textAlign: 'left',
    flexDirection: 'column',
  };
  const textStyle1 = {
    position: 'relative',
    fontWeight: 'bold',
    fontSize: '40px',
    color: 'white',
  };
  const textStyle2 = {
    position: 'relative',
    letterSpacing: '-2px',
    fontWeight: 400,
    fontSize: '60px',
    color: 'white',
  };
  const buttonContainerStyle = {
    position: 'relative',
    padding: '20px 0 0 0',
  };
  const buttonStyle1 = {
    padding: '20px 30px',
    fontSize: '16px',
    margin: '10px 30px 0 0',
    fontWeight: 'bold',
    color: 'black',
    backgroundColor: 'orange',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s box-shadow 0.3s',  // Smooth transition for hover effects
    boxShadow: '20px 15px rgba(255, 165, 0, 0.5)',
  };
  const buttonStyle2 = {
    padding: '20px 30px',
    fontSize: '16px',
    margin: '10px 0 0 0',
    fontWeight: 'bold',
    color: 'black',
    backgroundColor: 'white',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s box-shadow 0.3s',  // Smooth transition for hover effects
    boxShadow: '-20px -15px rgba(255, 255, 255, 0.7)',
  };
  const content = {
    height: '500px',
  };
  const footer = {
    position: 'relative ',
    height: '100px',
    textAlign: 'centre',
    backgroundColor: 'black',
    color: 'white',
  };
  const navLink = {
    color: 'white',
    textDecoration: 'none',
    margin: '0 15px',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
  };


  const handleVisitUsClick = () => {
    window.open('https://sb.mindwaveai.com/demos/ent/DSRRv3.0/demo/mwtutor/usermanagement/backend/public/user/login', '_blank');
  };

  return (
    <div>
      <div style={containerStyle}>
        <div style={overlayStyle}></div>
        <div style={container}>
          <div>
            <div style={textStyle1}>AI Beyond Imagination</div>
            <div style={textStyle2}>Study Buddy</div>
          </div>
          <div style={buttonContainerStyle}>
            <button style={buttonStyle1} onClick={handleVisitUsClick}>Visit Us</button>
            <button style={buttonStyle2}>Contact</button>
          </div>
        </div>
      </div>
      <div style={content} id='learnmore'>
      </div>
      <div style={footer}>
        <div>Copyright © 2024 TipTop Technologies | All Rights Reserved</div>
        <div>
          <a href='' target='blank' style={navLink}><i className="fa-brands fa-linkedin"></i></a>
          <a href='' target='blank' style={navLink}><i className="fa-brands fa-instagram"></i></a>
          <a href='' target='blank' style={navLink}><i className="fa-brands fa-facebook"></i></a>
          <a href='' target='blank' style={navLink}><i className="fa-brands fa-x-twitter"></i></a>
        </div>
      </div> 
    </div>
  );
};

export default StudyBuddy;
