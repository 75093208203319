import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom'; // Import Link and useNavigate component
import mind360 from '../images/mind360.jpg';
import studybuddy from '../images/studybuddy.png';
import mindwave from '../images/mindwave.png';
import alphastreet from '../images/alphastreet.png';
import logo from '../logo.svg';
import tech from '../images/tech.jpg'; 
import sblogo from '../images/studybuddy-logo.png';
import aslogo from '../images/aslogo.svg';
import akubelogo from '../images/akubelogo.png';
import dnlogo from '../images/dnlogo.webp';
import '../App.css';

function LandingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const logoRef = useRef(null);
  const cardRef = useRef(null);

  useEffect(() => {
    if (location.state && location.state.scrollToContent) {
      document.getElementById('learnmore').scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const divisions = [
    { text1: 'Explore Endless Possibilities', text2: 'Mind-360', backgroundImage: mind360, button1: '/', button2: '' },
    { text1: 'AI Beyond Imagination', text2: 'Study Buddy', backgroundImage: studybuddy, button1: '/studybuddy', button2: '' },
    { text1: 'Market Moves Fast', text2: 'Alpha Street', backgroundImage: alphastreet, button1: '/alphastreet', button2: '' }
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === divisions.length - 1 ? 0 : prevIndex + 1));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const leftClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? divisions.length - 1 : prevIndex - 1));
  };

  const rightClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === divisions.length - 1 ? 0 : prevIndex + 1));
  };

  const currentDivision = divisions[currentIndex];

  const containerStyle = {
    position: 'relative',
    top: '0px',
    width: '100%',
    height: '515px',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${currentDivision.backgroundImage})`,
    backgroundSize: 'cover',
  };

  const overlayStyle = {
    position: 'absolute',
    width: '100%',
    height: '120%',
    backgroundImage: `url(${mindwave}), radial-gradient(rgba(0,0,0,0.5) 10%, rgba(0,0,0,0.9) 60%)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundBlendMode: 'overlay',
    filter: 'brightness(4)',
  };

  const container = {
    position: 'absolute',
    top: '40%',
    left: '100px',
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
  };

  const textStyle1 = {
    position: 'relative',
    fontWeight: 'bold',
    fontSize: '40px',
    color: 'white',
  };

  const textStyle2 = {
    position: 'relative',
    letterSpacing: '-4px',
    fontWeight: 400,
    fontSize: '60px',
    color: 'white',
  };

  const buttonContainerStyle = {
    position: 'relative',
    padding: '20px 0 0 0',
  };

  const buttonStyle1 = {
    padding: '20px 30px',
    fontSize: '16px',
    margin: '10px 30px 0 0',
    fontWeight: 'bold',
    color: 'black',
    backgroundColor: 'orange',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s, box-shadow 0.3s',  // Smooth transition for hover effects
    boxShadow: '20px 15px rgba(255, 165, 0, 0.5)',
  };

  const buttonStyle2 = {
    padding: '20px 30px',
    fontSize: '16px',
    margin: '10px 0 0 0',
    fontWeight: 'bold',
    color: 'black',
    backgroundColor: 'white',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s, box-shadow 0.3s',  // Smooth transition for hover effects
    boxShadow: '-20px -15px rgba(255, 255, 255, 0.7)',
  };

  const content1 = {
    position: 'relative',
    backgroundImage: `url(${tech})`,
    padding: '0 0 50px 0',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    zIndex: -1,
    display: 'flex',
    justifyContent: 'center',
  };

  const cardStyle1 = {
    display: 'flex',
    width: '90%',
    height: 'auto',
    margin: '100px 0 0 0',
  };

  const cardTextStyle = {
    flex: 5,
    color: 'white',
  };

  const head1 = {
    display: 'flex',
    margin: '50px 10px 10px 10px',
    fontWeight: 1000,
    fontSize: '100px',
    color: 'orange',
  }

  const para1 = {
    display: 'flex',
    margin: '10px',
    textAlign: 'left',
    fontSize: '20px',
  }

  const cardImageStyle = {
    flex: 2,
    padding: '300px 0 0 0',
  };

  const content2 = {
    position: 'relative',
    padding:'0 0 30px 0',
    backgroundColor: 'orange',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    zIndex: -1,
    display: 'flex',
    justifyContent: 'center',
  };

  const cardStyle2 = {
    display: 'flex',
    width: '100%',
    height: 'auto',
    // border: '1px white solid',
  };

  const cardTextStyle2 = {
    flex: 6,
    color: 'white',
    // border: '1px white solid',
  };

  const head2 = {
    display: 'flex',
    margin: '15px 50px',
    fontWeight: 700,
    fontSize: '50px',
    // border: '1px white solid',
  }

  const para2 = {
    display: 'flex',
    margin: '20px 50px',
    textAlign: 'left',
    fontSize: '20px',
    // border: '1px white solid',
  }

  const cardImageStyle2 = {
    flex: 1,
    // border: '1px white solid',
  };

  const sblogoimg = {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: '30px',
    margin: '-10px 0 50px 0',
    boxShadow: '10px 10px rgba(0, 0, 0, 0.5)',
  };

  const content3 = {
    position: 'relative',
    padding: '0 0 30px 0',
    backgroundColor: 'lightgreen',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    zIndex: -1,
    display: 'flex',
    justifyContent: 'center',
    //  border: '1px black solid',
  };

  const cardStyle3 = {
    display: 'flex',
    width: '100%',
    height: 'auto',
    //  border: '1px black solid',
  };

  const cardTextStyle3 = {
    flex: 6,
    color: 'white',
    //  border: '1px black solid',
  };

  const head3 = {
    display: 'flex',
    margin: '15px 50px',
    fontWeight: 700,
    fontSize: '50px',
    //  border: '1px black solid',
  }

  const para3 = {
    display: 'flex',
    margin: '20px 50px',
    textAlign: 'left',
    fontSize: '20px',
    //  border: '1px black solid',
  }

  const cardImageStyle3 = {
    flex: 1,
    //  border: '1px black solid',
  };

  const aslogoimg = {
    backgroundColor: 'white',
    padding: '30px',
    margin: '-10px 50px 0 0',
    boxShadow: '10px 10px rgba(0, 0, 0, 0.5)',
  };

  const content4 = {
    position: 'relative',
    padding: '0 0 30px 0',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    backgroundColor: 'lightskyblue',
    backgroundRepeat: 'no-repeat',
    zIndex: -1,
    display: 'flex',
    justifyContent: 'center',
    //  border: '1px black  solid',
  };

  const cardStyle4 = {
    display: 'flex',
    width: '100%',
    height: 'auto',
    //  border: '1px black solid',
  };

  const cardTextStyle4 = {
    flex: 6,
    color: 'white',
    //  border: '1px black solid',
  };

  const head4 = {
    display: 'flex',
    margin: '15px 50px',
    fontWeight: 700,
    fontSize: '50px',
    //  border: '1px black solid',
  }

  const para4 = {
    display: 'flex',
    margin: '20px 50px',
    textAlign: 'left',
    fontSize: '20px',
    //  border: '1px black solid',
  }

  const cardImageStyle4 = {
    flex: 1,
    //  border: '1px black solid',
  };

  const logo360img = {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: '50px 30px',
    margin: '-10px 0 50px 0',
    boxShadow: '10px 10px rgba(0, 0, 0, 0.5)',
  };

  const content5 = {
    position: 'relative',
    padding: '0 0 30px 0',
    backgroundColor: '#ec944c',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    zIndex: -1,
    display: 'flex',
    justifyContent: 'center',
    //  border: '1px black solid',
  };

  const cardStyle5 = {
    display: 'flex',
    width: '100%',
    height: 'auto',
    //  border: '1px black solid',
  };

  const cardTextStyle5 = {
    flex: 6,
    color: 'white',
    //  border: '1px black solid',
  };

  const head5 = {
    display: 'flex',
    margin: '15px 50px',
    fontWeight: 700,
    fontSize: '50px',
    //  border: '1px black solid',
  }

  const para5 = {
    display: 'flex',
    margin: '20px 50px',
    textAlign: 'left',
    fontSize: '20px',
    //  border: '1px black solid',
  }

  const cardImageStyle5 = {
    flex: 1,
    //  border: '1px black solid',
  };

  const dnlogoimg = {
    backgroundColor: 'white',
    padding: '30px',
    margin: '-10px 50px 0 0',
    boxShadow: '10px 10px rgba(0, 0, 0, 0.5)',
  };

  const content6 = {
    position: 'relative',
    padding: '0 0 30px 0',
    backgroundColor: 'white',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    zIndex: -1,
    display: 'flex',
    justifyContent: 'center',
    //  border: '1px black solid',
  };

  const cardStyle6 = {
    display: 'flex',
    width: '100%',
    height: 'auto',
    //  border: '1px black solid',
  };

  const cardTextStyle6 = {
    flex: 6,
    // border: '1px black solid',
  };

  const head6 = {
    display: 'flex',
    margin: '15px 50px',
    fontWeight: 700,
    fontSize: '50px',
    justifyContent: 'center',
    // border: '1px black solid',
  }

  const para6 = {
    display: 'flex',
    margin: '20px 50px',
    justifyContent: 'center',
    fontSize: '20px',
    // border: '1px black solid',
  }

  const cardImageStyle6 = {
    flex: 1,
    // border: '1px black solid',
  };

  const finallogo = {
    backgroundColor: 'white',
    padding: '15px 15px',
    margin: '-10px 0 50px 0',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    boxShadow: '10px 10px rgba(0, 0, 0, 0.5)',
  };

  const search = {
    display: 'flex',
    flexDirection: 'column',
    // border: '1px black solid',
  };

  const searchbutton = {
    justifyContent: 'center',
  };

  const suggestbutton = {
    margin: '20px 5px 0 5px',
  }

  const footer = {
    position: 'absolute',
    width: '100%',
    height: '100px',
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    color: 'white',
  };

  const navLink = {
    color: 'white',
    textDecoration: 'none',
    margin: '0 15px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
  };

  const handleLearnMoreClick = (path) => {
    navigate(path, { state: { scrollToContent: true } });
  };
  const handleScroll = () => {
    if (cardRef.current) {
      const cardTop = cardRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (cardTop < windowHeight) {
        logoRef.current.classList.add('rotate');
        window.removeEventListener('scroll', handleScroll);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div style={containerStyle}>
        <div style={overlayStyle}></div>
        <div id="left" onClick={leftClick}>
          <div id="icon">
            <i className="fa-solid fa-chevron-left"></i>
          </div>
        </div>
        <div id="right" onClick={rightClick}>
          <div id="icon">
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
        <div style={container}>
          <div>
            <div style={textStyle1}>{currentDivision.text1}</div>
            <div style={textStyle2}>{currentDivision.text2}</div>
          </div>
          <div style={buttonContainerStyle}>
            <button style={buttonStyle1} onClick={() => handleLearnMoreClick(currentDivision.button1)}>Learn More</button>
            <Link to={currentDivision.button2}>
              <button style={buttonStyle2}>Contact</button>
            </Link>
          </div>
        </div>
      </div>
      <div style={content1}>
        <div style={cardStyle1} ref={cardRef} id='learnmore'>
          <div style={cardTextStyle}>
            <h1 style={head1}>Mind-360</h1>
            <p style={para1}>Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum? amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum?</p>
          </div>
          <div style={cardImageStyle}>
            <img src={logo} ref={logoRef} width="200px"/>
          </div>
        </div>
      </div>
      <div style={content2}>
        <div style={cardStyle2}>
          <div style={cardImageStyle2}>
            <img src={sblogo} style={sblogoimg} width="110px"/>
          </div>
          <div style={cardTextStyle2}>
            <h2 style={head2}>Study Buddy</h2>
            <p style={para2}>Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum? amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum?</p>
          </div>
        </div>
      </div>
      <div style={content3}>
        <div style={cardStyle3}>
          <div style={cardTextStyle3}>
            <h2 style={head3}>Alpha Street</h2>
            <p style={para3}>Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum? amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum?</p>
          </div>
          <div style={cardImageStyle3}>
            <img src={aslogo} style={aslogoimg}/>
          </div>
        </div>
      </div>
      <div style={content4}>
        <div style={cardStyle4}>
          <div style={cardImageStyle4}>
            <img src={akubelogo} width="110px" style={logo360img}/>
          </div>
          <div style={cardTextStyle4}>
            <h2 style={head4}>aKube</h2>
            <p style={para4}>Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum? amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum?</p>
          </div>
        </div>
      </div>
      <div style={content5}>
        <div style={cardStyle5}>
          <div style={cardTextStyle5}>
            <h2 style={head5}>Digital Nirvana</h2>
            <p style={para5}>Lorem ipsum dolor sitLorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum? amet, consectetur adipisicing elit. Perspiciatis libero quas sed rem nulla. Autem tenetur quae doloribus libero, est a mollitia nulla obcaecati molestias tempore eos repellat ab, molestiae numquam. Quae eveniet explicabo vitae fuga tempore. Temporibus sint, voluptate libero voluptas at culpa ad error facere blanditiis, totam minus veritatis odit qui rerum impedit neque sequi voluptates repellat labore! Officiis maiores et facere, laudantium aut eos, reprehenderit corporis omnis illo laborum at aliquam dolor. Fugit pariatur dolorem facere dolorum?</p>
          </div>
          <div style={cardImageStyle5}>
            <img src={dnlogo} style={dnlogoimg}/>
          </div>
        </div>
      </div>
      <div style={content6}>
        <div style={cardStyle6}>
          <div style={cardImageStyle6}>
            <img src={logo} style={finallogo} width={'100px'}/>
          </div>
          <div style={cardTextStyle6}>
            <h2 style={head6}>Looking for something else?</h2>
            <div style={para6}>
              <div style={search}>
                <form style={searchbutton} class="form-inline">
                  <input class="form-control mr-sm-2" type="text" placeholder="Search Keyword" aria-label="Search"/>
                  <button class="btn btn-outline-warning my-2 my-sm-0" type="submit">Search</button>
                </form>
                <div>
                  <button style={suggestbutton} type="button" class="btn btn-outline-primary">360-Minutes</button>
                  <button style={suggestbutton} type="button" class="btn btn-outline-primary">News</button>
                  <button style={suggestbutton} type="button" class="btn btn-outline-primary">Chatbot</button>
                  <button style={suggestbutton} type="button" class="btn btn-outline-primary">Education</button>
                  <button style={suggestbutton} type="button" class="btn btn-outline-primary">T</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={footer}>
        <div>Copyright © 2024 TipTop Technologies | All Rights Reserved</div>
        <div>
          <a href='https://www.linkedin.com/company/tiptop-technologies/' target='blank' style={navLink}><i className="fa-brands fa-linkedin"></i></a>
          <a href='https://www.instagram.com/360minutestt/' target='blank' style={navLink}><i className="fa-brands fa-instagram"></i></a>
          <a href='https://en.wikipedia.org/wiki/TipTop_Technologies' target='blank' style={navLink}><i className="fa-brands fa-wikipedia-w"></i></a>
          <a href='https://x.com/360_tiptop' target='blank' style={navLink}><i className="fa-brands fa-twitter"></i></a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
