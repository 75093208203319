import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import tiptopLogo from './logo.svg';
import LandingPage from './routes/LandingPage';
import Products from './routes/Products';
import StudyBuddy from './routes/StudyBuddy';
import Aboutus from './routes/aboutus';
import AlphaStreet from './routes/AlphaStreet';

import './App.css';

function App() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header style={isScrolled ? styles.headerScrolled : styles.header}>
          <Link to={'/'}>
            <img src={tiptopLogo} alt="TipTop Technologies Logo" style={isScrolled ? styles.logoScrolled : styles.logo}/>
          </Link>
          <nav style={styles.nav}>
            <Link to="/" style={styles.navLink}>Home</Link>
            <Link to="/aboutus" style={styles.navLink}>About Us</Link>
            <Link to="/products" style={styles.navLink}>Products</Link>
            <Link to="/flyer" style={styles.navLink}>Contact</Link>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<LandingPage />} />  
          <Route path="/products" element={<Products />} />  
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/studybuddy" element={<StudyBuddy />} />
          <Route path="/alphastreet" element={<AlphaStreet />} />
          <Route path="*" element={() => <div>404 Not Found</div>} /> {/* Fallback page for undefined routes */}
        </Routes>
      </div>
    </Router>
  );
}

const styles = {
  header: {
    top: '0px',
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.9)',
    width: '100%',
    zIndex: 9999,
    fontWeight: 500,
    lineHeight: '23px',
    padding: '10px 0',
    transition: 'height 0.4s ease, padding 0.4s ease',
  },
  headerScrolled: {
    top: '0px',
    position: 'fixed',
    background: 'rgba(0, 0, 0,0.9)',
    width: '100%',
    zIndex: 9999,
    fontWeight: 500,
    lineHeight: '23px',
    padding: '5px 0',
    transition: 'height 0.4s ease, padding 0.4s ease',
  },
  logo: {
    cursor: 'pointer',
    height: '60px',
    verticalAlign: 'middle',
    transition: 'height 0.4s ease',
  },
  logoScrolled: {
    cursor: 'pointer',
    height: '40px',
    verticalAlign: 'middle',
    transition: 'height 0.4s ease',
  },
  nav: {
    display: 'inline-block',
    marginLeft: '20px',
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    margin: '0 15px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'background-color 0.4s, color 0.4s',
  },
};

export default App;
