import React from 'react';
import '../App.css';

function Products() {
  const gridContainer = {
    position: 'relative',
    top: '90px',
    // border: '1px red solid',
  };

  const productbox = {
    backgroundColor: 'white',
    textAlign: 'center',
    // border: '1px red solid',
  };

  const prodLink = {
    textDecoration: 'none',
    // border: '1px red solid',
  };

  const product = {
    color: 'red',
    fontSize: '30px',
    // border: '1px red solid',
  };

  return (
    <div>
      <div style={gridContainer}>
        <div className="w3-row-padding">
          <div className="w3-third w3-container w3-margin-bottom">
            <div style = {productbox} class="w3-container">
              <a href="studybuddy" style={prodLink}>
                <div style = {product}>Study Buddy</div>
                <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
              </a>
            </div>
          </div>
          <div className="w3-third w3-container w3-margin-bottom">
            <div style = {productbox}class="w3-container">
              <a href="alphastreet" style={prodLink}>
                <div style = {product}>Alpha Street</div>
                <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
              </a>
            </div>
          </div>
          <div className="w3-third w3-container w3-margin-bottom">
            <div style = {productbox} class="w3-container">
              <a href="akube" style={prodLink}>
                <div style = {product}>aKube</div>
                <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
              </a>
            </div>
          </div>
        </div>
        <div className="w3-row-padding">
          <div className="w3-third w3-container w3-margin-bottom">
            <div style = {productbox} class="w3-container">
              <a href="studybuddy" style={prodLink}>
                <div style = {product}>Digital Nirvana</div>
                <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
              </a>
            </div>
          </div>
          <div className="w3-third w3-container w3-margin-bottom">
            <div style = {productbox} class="w3-container">
              <a href="alphastreet" style={prodLink}>
                <div style = {product}>360 Minutes</div>
                <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
              </a>
            </div>
          </div>
          <div className="w3-third w3-container w3-margin-bottom">
            <div style = {productbox} class="w3-container">
              <a href="digitalnirvana" style={prodLink}>
                <div style = {product}>Edu 360</div>
                <p>Praesent tincidunt sed tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
